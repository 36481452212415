@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=League+Spartan:wght@100;200;300;400;500;600;700;800;900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

html, body {
  height: 100%;
  font-size: 12px;
  background-color: #e8e8e8;
  font-family: 'Lato', sans-serif;
  font-family: 'League Spartan', sans-serif;
  font-family: 'Open Sans', sans-serif;
  text-align: center;
}

/* elements */
.divider {
  margin: 16px 0;
  border: 2px solid;
  border-top: none;
}

.cardLink {
  text-decoration: none;
  color: inherit; /* To inherit the color of the content inside, modify accordingly */
  display: block; /* To make sure it takes the full width and height of its content */
}

/* PageHeader */
.pageHeader {
  color: #333;
  font-size: 3rem;
  margin: 0;
}

span.slash {
  font-weight: bolder;
}

/* TextInputField, TextInputArea */
.textInputField input, .textInputArea textarea {
  transition: all 0.3s; /* Smooth transition for the changes */
  border: 1px solid #ccc;
  padding: 10px;
  outline: none; /* Remove the default browser outline */
  width: 100%; /* Makes sure the textarea/input takes the full width of its container */
  box-sizing: border-box; /* Ensures padding and border are included in the width */
  resize: vertical; /* Allow vertical resizing, prevent horizontal resizing for textarea */
}

.textInputField input:focus, .textInputArea textarea:focus {
  border-color: #5c5c5c; /* Example blue color for active state */
  box-shadow: 0 0 5px rgba(48, 48, 48, 0.5); /* Adding a subtle shadow */
}

.textInputArea textarea {
  min-height: 100px;
}

/* Button */
.button button {
  padding: 12px;
  border: 1px solid;
  border-radius: 0;
  background-color: white;
  cursor: pointer;
}

/* Bio */
.bio {
  height: 100vh;
  width: 70%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bio > .bio-header {
  font-size: 2rem;
}

.bio > .bio-keywords {
  font-size: 1.5rem;
  font-weight: 100;
}

/* Nav */
.nav {
  background-color: white;
  height: 24px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid;
}

.nav-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

.nav-item {
  margin: 0 15px;
}

.nav-item a {
  text-decoration: none;
  color: #333;
  transition: background-color 0.2s;
}

.nav-item a:hover {
  background-color: #e8e8e8;
  /* border-bottom: 1px solid; */
}

/* App Container */
.contentContainer {
  padding: 12px 48px;
  margin-top: 32px;
}

/* Card */
/* .cardContainer {

} */

.card {
  background-color: white;
  min-height: 300px;
  padding: 12px 24px;
  border: 1px solid #ddd;
  cursor: pointer;
  transition: 0.2s;
}
.card:hover {
  border: 1px solid black;
}

.cardHeader {
  color: #333;
}

.cardDescription {
  color: #686868;
}

/* General grid container styling */
.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 20px; /* Adjust as needed */
}

/* Default grid item styling (extra small screens) */
.grid-span-1 { grid-column: span 1; }
.grid-span-2 { grid-column: span 2; }
.grid-span-3 { grid-column: span 3; }
.grid-span-4 { grid-column: span 4; }
.grid-span-5 { grid-column: span 5; }
.grid-span-6 { grid-column: span 6; }
.grid-span-7 { grid-column: span 7; }
.grid-span-8 { grid-column: span 8; }
.grid-span-9 { grid-column: span 9; }
.grid-span-10 { grid-column: span 10; }
.grid-span-11 { grid-column: span 11; }
.grid-span-12 { grid-column: span 12; }

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .sm\:grid-span-1 { grid-column: span 1; }
  .sm\:grid-span-2 { grid-column: span 2; }
  .sm\:grid-span-3 { grid-column: span 3; }
  .sm\:grid-span-4 { grid-column: span 4; }
  .sm\:grid-span-5 { grid-column: span 5; }
  .sm\:grid-span-6 { grid-column: span 6; }
  .sm\:grid-span-7 { grid-column: span 7; }
  .sm\:grid-span-8 { grid-column: span 8; }
  .sm\:grid-span-9 { grid-column: span 9; }
  .sm\:grid-span-10 { grid-column: span 10; }
  .sm\:grid-span-11 { grid-column: span 11; }
  .sm\:grid-span-12 { grid-column: span 12; }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .md\:grid-span-1 { grid-column: span 1; }
  .md\:grid-span-2 { grid-column: span 2; }
  .md\:grid-span-3 { grid-column: span 3; }
  .md\:grid-span-4 { grid-column: span 4; }
  .md\:grid-span-5 { grid-column: span 5; }
  .md\:grid-span-6 { grid-column: span 6; }
  .md\:grid-span-7 { grid-column: span 7; }
  .md\:grid-span-8 { grid-column: span 8; }
  .md\:grid-span-9 { grid-column: span 9; }
  .md\:grid-span-10 { grid-column: span 10; }
  .md\:grid-span-11 { grid-column: span 11; }
  .md\:grid-span-12 { grid-column: span 12; }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .lg\:grid-span-1 { grid-column: span 1; }
  .lg\:grid-span-2 { grid-column: span 2; }
  .lg\:grid-span-3 { grid-column: span 3; }
  .lg\:grid-span-4 { grid-column: span 4; }
  .lg\:grid-span-5 { grid-column: span 5; }
  .lg\:grid-span-6 { grid-column: span 6; }
  .lg\:grid-span-7 { grid-column: span 7; }
  .lg\:grid-span-8 { grid-column: span 8; }
  .lg\:grid-span-9 { grid-column: span 9; }
  .lg\:grid-span-10 { grid-column: span 10; }
  .lg\:grid-span-11 { grid-column: span 11; }
  .lg\:grid-span-12 { grid-column: span 12; }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .xl\:grid-span-1 { grid-column: span 1; }
  .xl\:grid-span-2 { grid-column: span 2; }
  .xl\:grid-span-3 { grid-column: span 3; }
  .xl\:grid-span-4 { grid-column: span 4; }
  .xl\:grid-span-5 { grid-column: span 5; }
  .xl\:grid-span-6 { grid-column: span 6; }
  .xl\:grid-span-7 { grid-column: span 7; }
  .xl\:grid-span-8 { grid-column: span 8; }
  .xl\:grid-span-9 { grid-column: span 9; }
  .xl\:grid-span-10 { grid-column: span 10; }
  .xl\:grid-span-11 { grid-column: span 11; }
  .xl\:grid-span-12 { grid-column: span 12; }
}

/* PageLayout */
.pageLayout {
  display: flex;
  flex-direction: column;
  min-height: 100vh; 
}

/* Footer */
.pageLayout > div {
  flex: 1; 
}

.footer {
  text-align: center;
  padding: 80px 0;
  color: black;
}